import React from "react";
import "./Ielts.css";
import Images from "../../Images";

const Ielts = () => {
  return (
    <div className="ielts">
      <div className="back">
        <div className="hom">
          <div className="home-info">
            <span data-aos="fade-uspan">
              CREATING <span1>OPPORTUNITIES</span1>
            </span>
            <div data-aos="fade-up" className="animated-border"></div>
            <br />
            <p data-aos="fade-right">
              We prepare students for English Language tests
            </p>
          </div>
        </div>
      </div>

      {/* <div className="home-content">
        <h1>
          32 COUNTRIES | 1200+ UNIVERSITIES & COLLEGES
        </h1>
      </div> */}

      <div className="top-pic">
        <div className="top-content">
          <div className="heading-section">
            <h1>IELTS Introduction</h1>
          </div>
          <br />
          <div className="commonline"></div>
          <br />
          <p>
            The International English Language Testing System (IELTS) measures
            the language proficiency of people who want to study or work where
            English is used as a language of communication. It uses a nine-band
            scale to clearly identify levels of proficiency.
          </p>
          <br />
          <span>IELTS Academic or IELTS General Training</span>
          <br />
          <p>
            IELTS is available in two test versions: Academic – for people
            applying for higher education or professional registration, and
            General Training for those migrating to Australia, Canada and the
            UK, or applying for secondary education, training programmes and
            work experience in an English-speaking environment. Both versions
            provide a valid and accurate assessment of the four language skills:
            Listening, Reading, Writing and Speaking.
          </p>
          <br />
          <span>IELTS Writing marking schemes</span>
          <br />
          <ul>
            <li>Task Achievement</li>
            <li>Coherence and Cohesion</li>
            <li>Lexical Resource</li>
            <li>Grammatical Range and Accuracy</li>
          </ul>
          <br />
          <span>Monthly Fees is ₹5000/-</span>
        </div>

        <div className="top-contact">
          <div className="contactoption">
            <span>Avail a complementary Session</span>
            <br />
            <a href="/Contact">Book Now</a>
          </div>
        </div>
      </div>

      {/* <div className="trfs">
        <div className="mainline">
          <h2>
            More than 85% FlyingFeathers students scored 8+ band in IELTS band
            or equivalent in PTE in the last 2 years. Now, you can join that
            list.
          </h2>
        </div>
        <div className="trf-results">//</div>
      </div> */}
    </div>
  );
};

export default Ielts;
