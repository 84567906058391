import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div data-aos="fade-up" className="f-line"></div>
        <div className="f-content">
          <div className="logo-social">
            <div className="ls-logo">
              <span>Language And Visa</span>
              <p data-aos="fade-up">
                The main motive of our institute is to guide the students and
                train them effectively to crack their IELTS and PTE exam.
              </p>
            </div>
            <div className="newsletter">
              <form className="newsletter-form">
                <input type="email" id="email" placeholder="Email" required />
                <button type="submit">Subscribe</button>
              </form>
            </div>
          </div>

          <div className="f-tags">
            <div className="f-heading">
            <span>Links</span>
            </div>
            <div className="f-links">
              <a href="/About">About</a>
              <a href="/Contact">Contact</a>
              <a href="/Refundpolicy">Refund Policy</a>
              <a href="/Privacypolicy">Privacy Policy</a>
              <a href="/Termsandconditions">Terms and Conditions</a>
            </div>
          </div>

          <div className="getintouch">
            <div className="a-box">
              <div className="address">
                <div className="address-heading">
                  <span>Address</span>
                </div>
                <div className="actual-address">
                  <a href="https://maps.app.goo.gl/SudfdnsBFmZ1FXoe6">
                    1st Floor, Harjas Complex,
                    <br />
                    Near Dhamot Pertrol Pump,
                    <br />
                    Rajbaha Road, PATIALA.
                  </a>
                </div>
              </div>
              <div className="address">
                <div className="address-heading">
                  <span>Phone</span>
                </div>
                <div className="actual-address">
                  <a href="tel:6239611127">+91 6239611127</a>
                </div>
              </div>
            </div>
            <div className="a-box">
              <div className="address">
                <div className="address-heading">
                  <span>Email</span>
                </div>
                <div className="actual-address">
                  <a href="mailto: languageandvisa@gmail.com">
                    languageandvisa@gmail.com
                  </a>
                </div>
              </div>
              <div className="address">
                <div className="address-heading">
                  <span>Office Hours</span>
                </div>
                <div className="actual-address">
                  <p>9AM-6PM</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="f-line"></div>
        <div className="bottom">
          <div className="copyright">
            <p>Copyright © [Language And Visa] All Rights Reserved.</p>
            <p>Operated by Yuvraj Singh Manhas.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
