import React from "react";
import "./Pte.css";
import Images from "../../Images";

const Pte = () => {
  return (
    <div className="ielts">
      <div className="back">
        <div className="hom">
          <div className="home-info">
            <span data-aos="fade-uspan">
              CREATING <span1>OPPORTUNITIES</span1>
            </span>
            <div data-aos="fade-up" className="animated-border"></div>
            <br />
            <p data-aos="fade-right">
              We prepare students for English Language tests
            </p>
          </div>
        </div>
      </div>

      {/* <div className="home-content">
        <h1>
          32 COUNTRIES | 1200+ UNIVERSITIES & COLLEGES
        </h1>
      </div> */}

      <div className="top-pic">
        <div className="top-content">
          <div className="heading-section">
            <h1>PTE Introduction</h1>
          </div>
          <br />
          <div className="commonline"></div>
          <br />
          <p>
            To complete a PTE Academic test, you will need to book a secure
            Pearson test center. You will use a computer and headset to listen
            to, read and respond to questions. During the three-hour test
            session there will be three main parts to the test: speaking and
            writing (together), listening and reading. There are twenty
            different question formats, ranging from multiple choice through to
            essay writing and interpreting information. PTE Academic assesses
            real-life, academic English, so you will hear excerpts from lectures
            and view graphs and charts. You will hear a range of accents in the
            test, from British and American to non-native speakers, so you will
            be exposed to the type of accents you will encounter in everyday
            life.
          </p>
          <br />
          <span>Monthly Fees is ₹5000/-</span>
          {/* <br />
          <span>Test Format</span>
          <br />
          <div className="top-grid">

          </div> */}
        </div>

        <div className="top-contact">
          <div className="contactoption">
            <span>Avail a complementary Session</span>
            <br />
            <a href="/Contact">Book Now</a>
          </div>
        </div>
      </div>

      {/* <div className="trfs">
        <div className="mainline">
          <h2>
            More than 85% FlyingFeathers students scored 8+ band in IELTS band
            or equivalent in PTE in the last 2 years. Now, you can join that
            list.
          </h2>
        </div>
        <div className="trf-results">//</div>
      </div> */}
    </div>
  );
};

export default Pte;
