import React from 'react'
import '../Termsandconditions/Terms.css'

const Refund = () => {
  return (
    <div className='termsbody'>
        <div class="containerr">
        <h1>Refund Policy</h1>
        <p><strong>Effective Date:</strong> [9th January 2024]</p>

        <p>At <strong>Language and Visa</strong>, we strive to provide the highest quality services. This Refund Policy outlines the conditions under which refunds may be granted for our services, including IELTS and PTE classes.</p>
<br />
        <h2>1. Eligibility for Refund</h2>
        <ul>
            <li>Refunds are only applicable if a written request is submitted to the company within the specified timeframes outlined below.</li>
            <li>Refunds will not be granted for missed classes or failure to attend after course commencement.</li>
            <li>Refund eligibility depends on the payment method and the time of request in relation to the start date of the course or service.</li>
        </ul>
        <br/>
        <h2>2. Refund Request Timeframes</h2>
        <ul>
            <li><strong>Before Course Start:</strong> Full refund if the request is made at least 7 days before the start of the course.</li>
            <li><strong>Within 7 Days of Start:</strong> 50% refund if the request is made within 7 days of the course start date.</li>
            <li><strong>After 7 Days of Start:</strong> No refunds will be granted after this period.</li>
        </ul>
        <br/>
        <h2>3. Non-Refundable Fees</h2>
        <ul>
            <li>Registration fees and administrative charges are non-refundable under any circumstances.</li>
            <li>Materials, including books and digital resources, once issued, are not eligible for refund.</li>
        </ul>
        <br/>
        <h2>4. Refund Process</h2>
        <ul>
            <li>All refund requests must be submitted in writing via email or through our office, along with proof of payment and a valid reason for the request.</li>
            <li>Once refund is processed it will be credited in bank account within 5-7 business working days.</li>
        </ul>
        <br/>
        <h2>5. Cancellations by Language and Visa</h2>
        <ul>
            <li>If a course or service is canceled by Language and Visa, participants will be offered a full refund or the option to transfer to a different course or session.</li>
        </ul>
        <br/>
        <h2>6. Amendments to the Refund Policy</h2>
        <p>Language and Visa reserves the right to modify this Refund Policy at any time. Any changes will be communicated through our website or directly to affected participants.</p>
        <br/>
        <div class="contactss">
            <h2>7. Contact Us</h2>
            <p>If you have any questions regarding this Refund Policy, please contact us at:</p>
            <p><strong>Language and Visa</strong><br/>
            [1st Floor, Harjas Complex,
Near Dhamot Pertrol Pump,
Rajbaha Road, PATIALA.]<br/>
            [languageandvisa@gmail.com]<br/>
            [+91 62396-11127]</p>
        </div>
    </div>
    </div>
  )
}

export default Refund