import React from 'react'
import '../Termsandconditions/Terms.css'

const Privacy = () => {
  return (
    <div className='termsbody'>
        <div class="containerr">
        <h1>Privacy Policy</h1>
        <p><strong>Effective Date:</strong> [9th January 2024]</p>

        <p>At <strong>Language and Visa</strong>, we are committed to protecting the privacy and security of our clients’ personal information. This Privacy Policy outlines how we collect, use, and safeguard your data in compliance with applicable laws.</p>
<br />
        <h2>1. Information We Collect</h2>
        <ul>
            <li><strong>Personal Information:</strong> Name, email address, phone number, address, date of birth, and identification documents provided during registration or inquiries.</li>
            <li><strong>Payment Information:</strong> Details related to payments for our services, such as billing address and transaction details. Payment details are processed securely through third-party payment gateways.</li>
            <li><strong>Usage Data:</strong> Information about how you interact with our website or online resources, including IP address, browser type, and pages visited.</li>
        </ul>
        <br/>
        <h2>2. How We Use Your Information</h2>
        <ul>
            <li>To process your enrollment and provide the requested services.</li>
            <li>To communicate important updates, such as class schedules, changes to policies, or promotional offers.</li>
            <li>To improve our services, website, and customer support.</li>
            <li>To comply with legal obligations or resolve disputes.</li>
        </ul>
        <br/>
        <h2>3. Sharing Your Information</h2>
        <ul>
            <li><strong>Third-Party Service Providers:</strong> We may share your information with trusted third parties who assist in delivering our services (e.g., payment processors, IT support).</li>
            <li><strong>Legal Requirements:</strong> We may disclose information if required by law or to protect our rights, property, or safety.</li>
            <li><strong>Consent:</strong> We will not share your information with other organizations without your explicit consent.</li>
        </ul>
        <br/>
        <h2>4. Data Security</h2>
        <p>We implement robust security measures to protect your personal information against unauthorized access, alteration, disclosure, or destruction. However, no method of data transmission over the Internet is 100% secure, and we cannot guarantee absolute security.</p>
        <br/>
        <h2>5. Your Rights</h2>
        <ul>
            <li>Access, update, or correct your personal information.</li>
            <li>Request the deletion of your personal data, subject to legal or contractual obligations.</li>
            <li>Opt-out of marketing communications by contacting us directly.</li>
        </ul>
        <br/>
        <h2>6. Retention of Information</h2>
        <p>We retain personal information only as long as necessary to fulfill the purposes outlined in this Privacy Policy or comply with legal obligations.</p>
        <br/>
        <h2>7. Cookies and Tracking Technologies</h2>
        <p>Our website may use cookies and similar technologies to enhance user experience and analyze website traffic. You can control cookie preferences through your browser settings.</p>
        <br/>
        <h2>8. Changes to This Policy</h2>
        <p>We may update this Privacy Policy from time to time. Any significant changes will be communicated via email or through our website.</p>
        <br/>
        <div class="contactss">
            <h2>9. Contact Us</h2>
            <p>If you have any questions about this Privacy Policy or how we handle your data, please contact us at:</p>
            <p><strong>Language and Visa</strong><br/>
            [1st Floor, Harjas Complex,
Near Dhamot Pertrol Pump,
Rajbaha Road, PATIALA.]<br/>
            [languageandvisa@gmail.com]<br/>
            [+91 62396-11127]</p>
        </div>
    </div>
    </div>
  )
}

export default Privacy