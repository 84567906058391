import React from "react";
import Images from "../../Images";
import "./About.css";

const About = () => {
  return (
    <div>
      <div className="about-page">
        <div data-aos="fade-right" className="about-page-img">
          <img src={Images.abpic} alt="" />
        </div>
        <div className="about-page-content">
          <div className="about-page-heading">
            <p data-aos="fade-up">About us</p>
            <h2 data-aos="fade-up">Language And Visa</h2>
            <span data-aos="fade-up">(IELTS And Immigration)</span>
            <p data-aos="fade-left">
              <span1>
                Welcome to Language and Visa - Your Key to Global Opportunities!
              </span1>
              <br />
              <br />
              Discover success with Language and Visa, your partner in mastering
              IELTS, PTE.
              {/* , and turning immigration dreams into reality */}
            </p>
            <p data-aos="fade-left">
              <span1>Why Choose Us?</span1> <br /> <br /> - Expert Guidance: Our
              experienced instructors are dedicated to
              your success.
              {/* and immigration experts */}
              <br /> - Tailored Courses: Your journey, your way. Benefit from
              personalized courses designed for your success.
              <br /> - Proven Success: Join a community of achievers. Language
              and Visa has a track record of making dreams a reality.
            </p>
            <p data-aos="fade-left">
              <span1>What We Offer:</span1>
              <br />
              <br /> - IELTS and PTE Excellence: Excel in language proficiency
              exams with our targeted courses.
              {/* <br /> - Immigration Made Easy: Navigate immigration confidently.
              We simplify the process for you. */}
              <br /> - Language Mastery: Beyond exams, we enhance your language
              skills for real-world success.
            </p>
            <p data-aos="fade-left">
              <span1>Your Journey Starts Here:</span1>
              <br />
              <br /> Language and Visa - where aspirations take flight. Your
              global adventure awaits! Ready to start? Contact us now!
            </p>
            <div data-aos="fade-up" className="about-page-btn">
              <a href="/Contact">
                Contact Us{" "}
                <i className="fa-solid fa-arrow-right fa-beat arrow"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="team">
        <div data-aos="fade-up" className="t-heading">
          <span>Our Dedicated Team</span>
          <div className="t-line"></div>
        </div>

        <div className="team-members">
          <div className="team-member">
            <div className="team-member-img">
              <img src={Images.abpic} alt="" />
            </div>
            <div className="team-member-content">
              <div className="team-member-name">
                <span>Yuvraj Sir</span>
                <p>Director</p>
              </div>
            </div>
          </div>
          <div className="team-member">
            <div className="team-member-img">
              <img src={Images.abpic} alt="" />
            </div>
            <div className="team-member-content">
              <div className="team-member-name">
                <span>Yuvraj Sir</span>
                <p>Director</p>
              </div>
            </div>
          </div>
          <div className="team-member">
            <div className="team-member-img">
              <img src={Images.abpic} alt="" />
            </div>
            <div className="team-member-content">
              <div className="team-member-name">
                <span>Yuvraj Sir</span>
                <p>Director</p>
              </div>
            </div>
          </div>
          <div className="team-member">
            <div className="team-member-img">
              <img src={Images.abpic} alt="" />
            </div>
            <div className="team-member-content">
              <div className="team-member-name">
                <span>Yuvraj Sir</span>
                <p>Director</p>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="testimonals">
        <div data-aos="fade-up" className="a-heading">
          <span>Testomonials</span>
          <div className="a-line"></div>
        </div>
        <div data-aos="fade-up" className="subheading">
          <span>
            What Our <span1>Students Say</span1>
          </span>
        </div>
      </div>
      <div className="testo-header">
        <div data-aos="fade-up" className="testo-container">
          <div className="testo-slider">
            <div className="testimonal" id="testimonal-1">
              <div className="user-text">
                <h3>Jaspreet kaur</h3>
                <br/>
                <p>
                  Best institute to learn English and learn ielts. Anyone can
                  complete ielts in just one month after studying from here.
                </p>
              </div>
              <div className="img">
                <img src={Images.girl1} alt="" className="user" />
              </div>
            </div>

            <div className="testimonal" id="testimonal-2">
              <div className="user-text">
                <h3>Mehakdeep Singh</h3>
                <br/>
                <p>
                  I have taken coaching from this centre in Patiala, I must say
                  this is the best.
                </p>
              </div>
              <div className="img">
                <img src={Images.boy1} alt="" className="user" />
              </div>
            </div>
            <div className="testimonal" id="testimonal-3">
              <div className="user-text">
                <h3>Gurleen Kaur</h3>
                <br/>
                <p>
                  Environment is friendly and cooperation of teachers always
                  encourage students to do well every time.
                </p>
              </div>
              <div className="img">
                <img src={Images.girl2} alt="" className="user" />
              </div>
            </div>
            <div className="testimonal" id="testimonal-4">
              <div className="user-text">
                <h3>Radhika</h3>
                <br/>
                <p>
                  I had an amazing experience at L&V. All the staff members
                  are very cooperative as well as trained. Well maintained
                  discipline is the most intriguing factor of this institution.
                </p>
              </div>
              <div className="img">
                <img src={Images.girl3} alt="" className="user" />
              </div>
            </div>
            <div className="testimonal" id="testimonal-5">
              <div className="user-text">
                <h3>Afreen Kaur</h3>
                <br/>
                <p>
                  I am satisfied with the services offered by L&V education
                  Patiala, i achieved my desired score with the help of
                  hardworking trainers and their efforts on students.
                </p>
              </div>
              <div className="img">
                <img src={Images.girl4} alt="" className="user" />
              </div>
            </div>
            <div className="testimonal" id="testimonal-6">
              <div className="user-text">
                <h3>Karanveer Singh</h3>
                <br/>
                <p>Excellent services, with cooperative staff.</p>
              </div>
              <div className="img">
                <img src={Images.girl5} alt="" className="user" />
              </div>
            </div>
          </div>
          <div className="testo-btn">
            <a href="#testimonal-1">
              <span className="dot"></span>
            </a>
            <a href="#testimonal-2">
              <span className="dot"></span>
            </a>
            <a href="#testimonal-3">
              <span className="dot"></span>
            </a>
            <a href="#testimonal-4">
              <span className="dot"></span>
            </a>
            <a href="#testimonal-5">
              <span className="dot"></span>
            </a>
            <a href="#testimonal-6">
              <span className="dot"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
