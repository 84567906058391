import React from "react";
import "./Contact.css";

const Contact = () => {
  return (
    <div>
      <div class="contact-page">
        <div class="map-form">
          <div data-aos="fade-up" class="map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13772.935740956995!2d76.394227!3d30.3443008!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391029c791170321%3A0x975cb89f218f820f!2sBand9IELTS!5e0!3m2!1sen!2sin!4v1694381079311!5m2!1sen!2sin"
              width="100%"
              height="450"
              style={{ border: 0 + "px" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div data-aos="fade-up" class="form">
            <div class="form-heading">
              <p>Contact Us</p>
            </div>

            <form action="">
              <div class="form-sec">
                <input type="text" placeholder="Name" required />
                <input type="text" placeholder="Email" required />
              </div>
              <div class="form-sec">
                <input type="text" placeholder="Phone" required />
                <input type="text" placeholder="Subject" required />
              </div>
              <div class="form-sec">
                <input type="text" placeholder="Message" required />
                <input type="text" placeholder="Subject" required />
              </div>
              <div class="form-button-btn">
                <button>Send Message</button>
              </div>
            </form>
          </div>
        </div>
      </div>
        <div className="business">
        <div className="getintouchbusiness">
            <div className="a-boxes">
              <div className="addresses">
                <div className="address-heading">
                  <span>Address</span>
                </div>
                <div className="actual-address">
                  <a href="https://maps.app.goo.gl/SudfdnsBFmZ1FXoe6">
                    1st Floor, Harjas Complex,
                    <br />
                    Near Dhamot Pertrol Pump,
                    <br />
                    Rajbaha Road, PATIALA.
                  </a>
                </div>
              </div>
              <div className="addresses">
                <div className="address-heading">
                  <span>Phone</span>
                </div>
                <div className="actual-address">
                  <a href="tel:6239611127">+91 6239611127</a>
                </div>
              </div>
            </div>
            <div className="a-boxes">
              <div className="addresses">
                <div className="address-heading">
                  <span>Email</span>
                </div>
                <div className="actual-address">
                  <a href="mailto: languageandvisa@gmail.com">
                    languageandvisa@gmail.com
                  </a>
                </div>
              </div>
              <div className="addresses">
                <div className="address-heading">
                  <span>Office Hours</span>
                </div>
                <div className="actual-address">
                  <p>9AM-6PM</p>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default Contact;
