import React from "react";
import "./Header.css";
import Images from "../../Images";
import VideoSlider from "../../data/VideoSlider/VideoSlider";

const Header = () => {
  return (
    <>
      <div className="back">
        <div className="homee">
          <div className="homee-info">
            <p data-aos="fade-up">Welcome to Language And Visa</p>
            <div data-aos="fade-up" className="animated-bd"></div>
            <span data-aos="fade-right">
              Your Gateway to IELTS and
              <br /> PTE Success
              {/* and Immigration
              <br /> Excellence. */}
            </span>
          </div>
        </div>
      </div>

      <div className="about-section">
        <div data-aos="fade-down" className="a-heading">
          <span>About Us</span>
          <div className="a-line"></div>
        </div>

        <div className="about">
          <div data-aos="fade-left" className="about-pic">
            <img src={Images.aboutpic1} alt="" />
          </div>
          <div className="content-about">
            <div data-aos="fade-right" className="content-a">
              <h2>
                <span>Language</span> And Visa
              </h2>
              <h3>(Formely Known as <span>Band9Ielts</span>)</h3>
              <br />
              <p>
                Language And Visa is a reputed and well established organization
                which provides excellent coaching for IELTS, PTE.
              </p>
              <br />
              <p>
                We are a license holding institute and our agents are RCIC
                Certified. Our expert team offers best guidance. We boast of
                100% results and transparency in our dealings. We are popular
                for Small Batches, Individual Attention, Highly Trained and
                Experienced Faculty, who make learning a memorable experience.
              </p>
              <br />
              <a href="/About">Learn More</a>
            </div>
          </div>
        </div>
      </div>

      <div className="services">
        <div className="services-section">
          <span data-aos="fade-right">
            DELIVERING HIGH-QUALITY <span1>COACHING</span1>
          </span>
          <p data-aos="fade-left">
            DELIVERING HIGH-QUALITY COACHING FOR IELTS, PTE, SPOKEN ENGLISH
          </p>
        </div>
        <div className="services-details">
          <a href="/Ielts" className="s-grid">
            <img src={Images.Books} alt="" />
            <span>IELTS Coaching</span>
          </a>
          <a href="/Pte" className="s-grid">
            <img src={Images.Books} alt="" />
            <span>PTE Coaching</span>
          </a>
          {/* <a href="/Immigration" className="s-grid">
            <img src={Images.Books} alt="" />
            <span>Immigration</span>
          </a> */}
        </div>
      </div>

      <div className="gallery">
        <div data-aos="fade-up" className="a-heading">
          <span>Our Memorable Moments</span>
          <div className="a-line"></div>
        </div>

        <div className="photo-grid">
          <div data-aos="flip-right" className="photo">
            <img src={Images.gallery6} alt="Photo 1" />
          </div>
          <div data-aos="flip-left" className="photo">
            <img src={Images.gallery8} alt="Photo 2" />
          </div>
          <div data-aos="flip-left" className="photo">
            <img src={Images.gallery9} alt="Photo 3" />
          </div>
          <div data-aos="flip-up" className="photo">
            <img src={Images.gallery5} alt="Photo 3" />
          </div>
          <div data-aos="flip-up" className="photo">
            <img src={Images.gallery10} alt="Photo 3" />
          </div>
          <div data-aos="flip-up" className="photo">
            <img src={Images.gallery11} alt="Photo 3" />
          </div>
          <div data-aos="flip-right" className="photo">
            <img src={Images.gallery7} alt="Photo 3" />
          </div>
          <div data-aos="flip-right" className="photo">
            <img src={Images.gallery4} alt="Photo 3" />
          </div>
          <div data-aos="flip-left" className="photo">
            <img src={Images.gallery} alt="Photo 3" />
          </div>
        </div>
      </div>

      {/* <div className="videoslider">
        <VideoSlider />
      </div> */}
    </>
  );
};

export default Header;
