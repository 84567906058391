import React from 'react'
import './Terms.css'

const Terms = () => {
  return (
    <div className='termsbody'>
        <div class="containerr">
        <h1>Terms and Conditions</h1>
        <p><strong>Effective Date:</strong> [9th January 2024]</p>

        <p>Welcome to <strong>Language and Visa</strong>! These Terms and Conditions govern your use of our services, including enrollment in IELTS and PTE classes. By enrolling in our courses, you agree to comply with the terms outlined below.</p>
    <br />
        <h2>1. Enrollment and Payment</h2>
        <ul>
            <li><strong>Eligibility:</strong>
                <ul>
                    <li>You must be at least 18 years old to enroll in our classes.</li>
                    <li>Minors (under 18 years) must provide written consent from a parent or guardian.</li>
                </ul>
            </li>
            <li><strong>Registration:</strong>
                <ul>
                    <li>Complete all required registration forms accurately and provide valid identification.</li>
                    <li>Seats are confirmed only upon full payment or as per an agreed payment schedule.</li>
                </ul>
            </li>
            <li><strong>Fees and Payments:</strong>
                <ul>
                    <li>Course fees are non-refundable unless otherwise specified in our Refund Policy.</li>
                    <li>Payments can be made via cash, bank transfer, or online payment methods as accepted by the company.</li>
                    <li>Late payments may result in suspension or cancellation of enrollment.</li>
                </ul>
            </li>
        </ul>
        <br />
        <h2>2. Class Schedules and Attendance</h2>
        <ul>
            <li><strong>Schedules:</strong>
                <ul>
                    <li>Class schedules are subject to change, and participants will be notified in advance.</li>
                    <li>The company reserves the right to reschedule classes due to unforeseen circumstances.</li>
                </ul>
            </li>
            <li><strong>Attendance:</strong>
                <ul>
                    <li>Regular attendance is mandatory to achieve course objectives.</li>
                    <li>Participants with less than 75% attendance may not be eligible for certification.</li>
                </ul>
            </li>
        </ul>
        <br />
        <h2>3. Course Materials and Intellectual Property</h2>
        <ul>
            <li><strong>Materials:</strong> All course materials provided by Language and Visa are for personal use only. Sharing, reproducing, or distributing materials without written consent is strictly prohibited.</li>
            <li><strong>Intellectual Property:</strong> All content, including but not limited to study guides, mock tests, and online resources, is the intellectual property of Language and Visa.</li>
        </ul>
        <br />
        <h2>4. Code of Conduct</h2>
        <ul>
            <li><strong>Behavior:</strong> Participants must maintain decorum and respect instructors, staff, and fellow students. Misconduct or disruptive behavior may result in immediate expulsion without refund.</li>
            <li><strong>Prohibited Activities:</strong>
                <ul>
                    <li>Use of mobile phones during class, except for educational purposes, is not allowed.</li>
                    <li>Recording or filming sessions without prior permission is prohibited.</li>
                </ul>
            </li>
        </ul>
        <br />
        <h2>5. Cancellation and Refund Policy</h2>
        <ul>
            <li><strong>Cancellations by Participants:</strong>
                <ul>
                    <li>Written notice is required for cancellations.</li>
                    <li>Refunds, if applicable, will be processed according to the company's Refund Policy.</li>
                </ul>
            </li>
            <li><strong>Cancellations by Language and Visa:</strong>
                <ul>
                    <li>In the unlikely event that the company cancels a course, participants will receive a full refund or credit for a future course.</li>
                </ul>
            </li>
        </ul>
        <br />
        <h2>6. Limitation of Liability</h2>
        <p>Language and Visa is not liable for any personal injury, property damage, or loss incurred during classes or on company premises.</p>
        <br />
        <h2>7. Privacy Policy</h2>
        <p>Your personal data will be collected, stored, and processed in accordance with our Privacy Policy.</p>
        <br />
        <h2>8. Amendments</h2>
        <p>Language and Visa reserves the right to modify these terms and conditions at any time. Participants will be notified of significant changes.</p>
        <br />
        <div class="contactss">
            <h2>9. Contact Us</h2>
            <p>If you have any questions or concerns, please contact us at:</p>
            <p><strong>Language and Visa</strong><br/>
            [1st Floor, Harjas Complex,
Near Dhamot Pertrol Pump,
Rajbaha Road, PATIALA.]<br/>
            [languageandvisa@gmail.com]<br/>
            [+91 62396-11127]</p>
        </div>
    </div>
    </div>
  )
}

export default Terms